<template>
  <b-modal v-model="shown" title="Create New Division" hide-footer @hide="onHide">
    <b-form @submit.prevent="onSubmit" @reset="onHide">
      <b-form-group label="Name:">
        <b-form-input :placeholder="$t('divisions.placeholder_name')" autocomplete="off" type="text" v-model="form.name" />
        <b-form-invalid-feedback :state="!v$.form.name.$invalid" v-if="v$.form.name.$errors[0]">
          {{ $t('validation.' + v$.form.name.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Workspaces:">
        <el-select :placeholder="$t('divisions.placeholder_workspaces')" v-model="form.workspaces_id" size="small" class="w-100">
          <el-option v-for="item in workspace_list" :key="item._id" :value="item._id" :label="item.name"></el-option>
        </el-select>
        <b-form-invalid-feedback :state="!v$.form.workspaces_id.$invalid" v-if="v$.form.workspaces_id.$errors[0]">
          {{ $t('validation.' + v$.form.workspaces_id.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required, minLength,
} from '@vuelidate/validators';
import divisionsApi from '../../../api/divisions';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'DivisionsCreate',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        workspaces_id: { required, minLength: minLength(1) },
      },
    };
  },
  data() {
    return {
      shown: false,
      form: {
        name: '',
        workspaces_id: '',
      },
      workspace_list: [],
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  methods: {
    onHide() {
      this.shown = false;
      this.$emit('onHide');
    },
    async onSubmit() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      this.$parent.showLoader();
      const response = await divisionsApi.create({
        name: this.form.name,
        workspaces_id: [this.form.workspaces_id],
        company_id: this.company_id,
      });
      this.$parent.hideLoader();

      await popupErrorMessages(response);
      this.$message({
        message: this.$t('divisions.success_create'),
        type: 'success',
      });
      this.onHide();
      this.$parent.getList();
    },
    getWorkspaces() {
      this.$store.dispatch('workspace/loadWorkspaces', {
        company_id: this.company_id,
        per_page: 100,
      }).then((res) => {
        this.workspace_list = res.data.rows;
      });
    },
  },
  watch: {
    show() {
      this.shown = this.show;
      if (this.shown) {
        this.v$.form.$touch();
        this.getWorkspaces();
      }
    },
  },
};
</script>
